import React, { useEffect } from "react";
import check from "../../assets/images/check.svg";
import {
  closeThanksPopup,
  openUsernameSetupModal,
} from "../../features/slices/ModalSlice";
import { useDispatch } from "react-redux";
import { getUserData } from "../../features/slices/UserSlice";
import { useMediaQuery } from "react-responsive";

export default function Thanks() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();

  // Add automatic transition after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(closeThanksPopup());
      dispatch(openUsernameSetupModal());
    }, 3000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, [dispatch]);

  // Update closeModal to use Redux actions
  const closeModal = () => {
    dispatch(closeThanksPopup());
    dispatch(openUsernameSetupModal());
  };

  return (
    <div>
      <div
        className={`modal fade show otp--modal`}
        style={{
          display: "block",
          backdropFilter: "blur(15px)",
          animation: "fadeIn 0.3s ease-in-out",
        }}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5"></h2>
              <i onClick={closeModal} className="bi bi-x-lg"></i>
            </div>
            <div className="modal-body mobile-otp-modal--body text-center">
              <div className="text-center">
                <img
                  loading="lazy"
                  src={check}
                  alt="verification-complete"
                  width={80}
                />
                <p className="text---large mt-4">Thanks for Registering</p>
                <p className="text-muted text--medium">
                  Sign Up has been successful!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
        .modal {
          animation: fadeIn 0.3s ease-in-out;
        }
      `}</style>
    </div>
  );
}
