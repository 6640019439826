import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "./slider";
import { GamesCarousel } from "./GamesCarousel";
import SearchField from "../common/SearchField";
import Popup from "../common/UsernameSetup";

import {
  getFavGames,
  getLastPlayedGames,
} from "../../features/slices/UserSlice";
import { openPaymentModal } from "../../features/slices/ModalSlice";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
export function Home() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);
  const game = useSelector((state) => state.game);
  const user = useSelector((state) => state.user);
  const [userFavs, setUserFavs] = useState([]);
  const [showUsernameSetup, setShowUsernameSetup] = useState(true);
  const [lastPlayedByUser, setlastPlayedByUser] = useState([]);
  useEffect(() => {
    if (user.userLoggedIn) {
      dispatch(getFavGames());
      dispatch(getLastPlayedGames());
    }
  }, [user.userLoggedIn]);

  useEffect(() => {
    let games = [];
    if (Object.keys(user.favGames).length) {
      for (const key in user.favGames) {
        let data = [];
        data = [...user.favGames[key].games];
        data.forEach((item) => games.push(item));
      }
    }
    setUserFavs(games);
  }, [user.favGames]);
  useEffect(() => {
    let games = [];
    if (Object.keys(user.lastPlayedGames).length) {
      for (const key in user.lastPlayedGames) {
        let data = [];
        data = [...user.lastPlayedGames[key].games];
        data.forEach((item) => games.push(item));
      }
    }
    setlastPlayedByUser(games);
  }, [user.lastPlayedGames]);

  useEffect(() => {
    if (localStorage.getItem("moveToFavourite")) {
      const section = document.getElementById("favGames");
      section.scrollIntoView({ behavior: "smooth" });
    }

    setTimeout(() => {
      localStorage.removeItem("moveToFavourite");
    }, 200);
    if (localStorage.getItem("openDeposit"))
      setTimeout(() => {
        dispatch(openPaymentModal("Deposit"));
        dispatch(updateSelectedItem("deposit"));
        localStorage.removeItem("openDeposit");
      }, 1000);
  }, []);

  return (
    <div>
      {showUsernameSetup && (
        <popup onClose={() => setShowUsernameSetup(false)} />
      )}
      <Carousel />
      <SearchField isHome={true} />
      <div className="container-fluid games-section custom-padding">
        <div className="row">
          {game.categories.map((item, index) => (
            <div
              className="col-lg-3 col-md-3 col-sm-6 col-6 text-center game-card"
              key={index}
            >
              <a href={`/${item.name}`}>
                <img loading="lazy" src={item.imagePath} />
              </a>
            </div>
          ))}
          <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center game-card">
            <a href="/all-games">
              <img
                loading="lazy"
                src="https://cdn.kubera365.com/kubera/frontend_images/category/all_games.webp"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="container-fluid custom-padding games-carousel"
        id="favGames"
      >
        {userFavs.length ? (
          <>
            <div>
              <h4 className="pb-3">Your Favourite</h4>
              <div className="mb-4">
                <GamesCarousel fav={true} data={userFavs} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {lastPlayedByUser.length ? (
          <>
            <div className="mt-5">
              <h4 className="pb-3">Last Played</h4>
              <div className="mb-4">
                <GamesCarousel fav={false} data={lastPlayedByUser} />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
