import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeUsernameSetupModal } from "../../features/slices/ModalSlice";
import { getUserData, updateUser } from "../../features/slices/UserSlice";
import { useMediaQuery } from "react-responsive";

export default function UsernameSetup() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isVisible = useSelector((state) => state.modal.openUsernameSetupModal);
  const userData = useSelector((state) => state.user.userData);
  const [username, setUsername] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const modalRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input field

  useEffect(() => {
    if (isVisible) {
      dispatch(getUserData({ source: "usernameSetup", showLoader: true }));
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    if (userData && userData.username) {
      //copy thr data to one more variab,e
      setUsername(userData.username);
    }
  }, [userData]);

  useEffect(() => {
    // Focus on the input field whenever entering edit mode
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleEditClick = () => setIsEditing(true);

  const handleUsernameChange = (event) => setUsername(event.target.value);

  const handleSaveUsername = () => {
    if (username.trim()) {
      // Check if the new username is different from the current username
      if (username === userData.username) {
        // If the username hasn't changed, close the modal and refresh user data
        console.log(
          "Username hasn't changed, closing modal and fetching user data."
        );
        dispatch(closeUsernameSetupModal());
        dispatch(getUserData({ source: "usernameSetup", showLoader: false }));
      } else {
        // If the username has changed, proceed with updating it
        dispatch(updateUser({ username }))
          .unwrap()
          .then(() => {
            dispatch(closeUsernameSetupModal());
          })
          .catch((error) => {
            console.error("Error updating username:", error);
            alert(
              error.message ||
                "An unexpected error occurred while updating the username."
            );
          });
      }
    }
  };
  const closeModal = () => {
    dispatch(closeUsernameSetupModal());
  };

  const handleOverlayClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  if (!isVisible) return null;

  return (
    <div
      onClick={handleOverlayClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        ref={modalRef}
        style={{
          backgroundColor: "#1a1a1a",
          borderRadius: "20px",
          padding: "20px",
          width: "460px",
          textAlign: "center",
          color: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 style={{ fontSize: "25px", color: "#ffffff" }}>Username Setup</h2>
          <button
            onClick={closeModal}
            style={{
              background: "none",
              border: "none",
              color: "white",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            ✖
          </button>
        </div>
        <hr
          style={{
            border: "0.5px solid #444",
            margin: "10px 0",
            width: "100%",
            marginBottom: "12px",
          }}
        />
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="username"
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            Username*
          </label>
          {isEditing ? (
            <input
              type="text"
              id="username"
              ref={inputRef} // Attach ref to the input field
              value={username}
              onChange={handleUsernameChange}
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "5px",
                border: "1px solid #444",
                backgroundColor: "#333",
                color: "white",
              }}
            />
          ) : (
            <div
              onClick={handleEditClick} // Enable edit mode on click
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "16px",
                backgroundColor: "#333",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer", // Change cursor to pointer
              }}
            >
              {username}{" "}
              <button
                onClick={handleEditClick}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#f0b90b",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                Edit
              </button>
            </div>
          )}
        </div>
        <button
          onClick={handleSaveUsername}
          style={{
            background:
              "linear-gradient(180deg, #F1B515 0%, #FFFB9A 53%, #E1900B 100%)",
            color: "#1D1D1D",
            width: "100%",
            padding: "10px",
            borderRadius: "9px",
            fontSize: "16px",
            marginBottom: "10px",
            cursor: "pointer",
            border: "none",
          }}
        >
          Keep this Username
        </button>
        <button
          onClick={closeModal}
          style={{
            backgroundColor: "transparent",
            border: "1px solid #f0b90b",
            color: "white",
            width: "100%",
            padding: "10px",
            borderRadius: "9px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          Skip It
        </button>
      </div>
      <style jsx>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
}
