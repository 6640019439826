import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { startLoading, stopLoading } from "./LoadingSlice";
import api from "../../api";
import Cookies from "js-cookie";
import { getUserInfo } from "../../app/utils";
import { openThanksPopup, closeUsernameSetupModal } from "./ModalSlice";

// Define the async thunk action creator

// Set the expiration time in minutes

export const createUser = createAsyncThunk(
  "user/createUser",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/createUser`, payload);
      const { countryCode, phone } = payload;
      const otpPayload = {
        countryCode: countryCode,
        phone: phone,
        attemptCount: 1,
      };
      dispatch(sendMobileOTP(otpPayload));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Server Error");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const sendMobileOTP = createAsyncThunk(
  "user/sendOTP",
  async (payload, { dispatch }) => {
    try {
      dispatch(startLoading());
      dispatch(resetMessage());
      const response = await api.post(`/otp/sendOTP`, payload);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const validateMobileOTP = createAsyncThunk(
  "user/validateOTP",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/otp/verifyOTP`, payload.data);

      // Open Thanks Popup after successful OTP validation
      dispatch(openThanksPopup());
      return {
        response: response.data,
        fromForgotPassword: payload.fromForgotPassword,
      };
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getUserData = createAsyncThunk(
  "user/getUserData",
  async (payload, { dispatch, rejectWithValue }) => {
    if (payload.showLoader) dispatch(startLoading());
    try {
      const response = await api.get(`/users/getUser?source=${payload.source}`);
      console.log(window.navigator.userAgent);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/updateUser`, payload);
      dispatch(getUserData({ source: "usernameSetup", showLoader: true }));
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (payload, { dispatch, rejectWithValue, getState }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/login`, payload);
      // const refToken = Cookies.get("refreshToken");
      // if (refToken) {
      //   dispatch(
      //     refreshJwtToken({ grantType: "refreshToken", refreshToken: refToken })
      //   );
      // }
      // getUserInfo()
      //   .then(async (data) => {
      //     try {
      //       const res = await api.post(`/users/saveLoginLogs`, data);
      //       console.log(res.data);
      //     } catch (error) {
      //       console.log("error saving login logs");
      //     }
      //   })
      //   .catch((error) => console.error("Error retrieving user info:", error));
      return response.data;
    } catch (error) {
      // Handle error condition
      if (
        error.response.data.code == 422 &&
        error.response.data.error.includes("Refresh Token")
      ) {
        const creds = getState().user.userCreds;
        dispatch(loginUser(creds));
        return;
      }
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getFavGames = createAsyncThunk(
  "user/favGames",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/users/listFavouriteGame`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getLastPlayedGames = createAsyncThunk(
  "user/astPlayedGame",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/users/listLastPlayedGame`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const makeFavourite = createAsyncThunk(
  "user/makeFavourite",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/makeFavouriteGame`, {
        gameId: payload.gameId,
      });
      if (!payload.fromCategroyPage) dispatch(getFavGames());
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const removeFavourite = createAsyncThunk(
  "user/removeFavourite",
  async (payload, { dispatch }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/unMakeFavouriteGame`, payload);
      dispatch(getFavGames());
      return response.data;
    } catch (error) {
      // Handle error condition
      throw Error("Error fetching user data");
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const getLoginLogs = createAsyncThunk(
  "user/getLoginLogs",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.get(`/users/getLoginLogs`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const uploadId = createAsyncThunk(
  "user/uploadId",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/verifyIdentity`, payload);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
export const logoutUser = createAsyncThunk(
  "users/logout",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    try {
      const response = await api.post(`/users/logout`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const refreshJwtToken = createAsyncThunk(
  "user/refreshToken",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post(`/login`, payload);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    }
  }
);

export const getTransactions = createAsyncThunk(
  "user/getTransactions",
  async (data, { dispatch, rejectWithValue }) => {
    dispatch(startLoading());
    const copyData = { ...data };
    let params = "?";
    for (const key in copyData) {
      if (typeof copyData[key] === "array") {
        copyData[key] = copyData[key].join(",");
      }
      params += `${key}=${copyData[key]}&`;
    }
    try {
      const response = await api.get(`/users/listTranscations${params}
      `);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);

export const getAstroData = createAsyncThunk(
  "user/getAstroData",
  async (showLoader = true, { dispatch, rejectWithValue }) => {
    if (showLoader) dispatch(startLoading());
    try {
      const response = await api.get(`/users/getAstroData`);
      return response.data;
    } catch (error) {
      // Handle error condition
      throw rejectWithValue(error.response.data);
    } finally {
      dispatch(stopLoading());
    }
  }
);
// Create a slice for your API-related actions and reducers
const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    otpMessage: "",
    error: null,
    otpVerified: false,
    userLoggedIn: false,
    userData: {},
    favGames: [],
    lastPlayedGames: [],
    madeFavourite: false,
    madeUnFavourite: false,
    userUpdated: false,
    loginLogs: [],
    otpSent: false,
    phoneDetails: {},
    documentsSubmitted: false,
    showAlreadyLoginMessage: true,
    alreadyLoggedData: {},
    loggedOut: false,
    showKeepWorkingPopup: false,
    expired: false,
    transactions: [],
    totalRecords: 0,
    continueSession: false,
    tokenRefreshed: false,
    userCreds: {},
    astroData: {},
  },
  reducers: {
    relogin(state) {
      state.userLoggedIn = false;
      state.error = null;
      state.userData = {};
      state.favGames = [];
      state.otpVerified = false;
      state.otpMessage = "";
      state.data = null;
      state.userUpdated = false;
      state.otpSent = false;
      state.alreadyLoggedData = {};
      state.showAlreadyLoginMessage = true;
      Cookies.remove("token");
      // Cookies.remove("refreshToken");
      localStorage.clear();
      sessionStorage.clear();
    },
    checkUserLogin(state) {
      if (Cookies.get("token")) state.userLoggedIn = true;
    },
    hideToast(state) {
      state.madeFavourite = false;
      state.madeUnFavourite = false;
    },
    storePhoneAndCountry(state, action) {
      state.phoneDetails = action.payload;
    },
    saveUserCreds(state, action) {
      state.userCreds = action.payload;
    },
    resetMessage(state) {
      state.otpMessage = "";
    },
    updateBalance(state, action) {
      state.userData.socketBalance = action.payload;
    },
    hidePopup(state) {
      state.showAlreadyLoginMessage = false;
    },
    showKeepWorkingModal(state) {
      state.showKeepWorkingPopup = true;
    },
    hideKeepWorkingModal(state) {
      state.showKeepWorkingPopup = false;
    },
    tokenExpired(state) {
      state.expired = true;
    },
    resetError(state) {
      state.error = null;
    },
    resetTransactions(state) {
      state.transactions = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {})
      .addCase(createUser.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.error = action.payload?.error || "An unknown error occurred";
      })
      .addCase(sendMobileOTP.fulfilled, (state, action) => {
        state.otpSent = true;
        state.error = "";
        state.otpMessage = action.payload.message || "OTP sent successfully";
      })
      .addCase(sendMobileOTP.rejected, (state, action) => {
        state.otpSent = false;
        state.error =
          action.error?.message || "User not found with given phone number";
      })
      .addCase(validateMobileOTP.fulfilled, (state, action) => {
        state.otpVerified = true;
        state.error = null;
        const token = action.payload.response.data.Authorization;
        const expirationMinutes = 60;
        const expirationDate = new Date();
        expirationDate.setTime(
          expirationDate.getTime() + expirationMinutes * 60 * 1000
        );
        if (token) {
          state.tokenRefreshed = true;
          if (action.payload.fromForgotPassword) {
            Cookies.set("tempToken", token);
            return;
          }
          Cookies.set("token", token, { expires: expirationDate });
          // Cookies.set("refreshToken", action.payload.data.refreshToken);
          Cookies.set("tokenExpires", expirationDate, {
            expires: expirationDate,
          });
        }
        state.userLoggedIn = true;
        getUserInfo()
          .then(async (data) => {
            try {
              const res = await api.post(`/users/saveLoginLogs`, data);
              console.log(res.data);
            } catch (error) {
              console.log("error saving login logs");
            }
          })
          .catch((error) =>
            console.error("Error retrieving user info:", error)
          );
      })
      .addCase(validateMobileOTP.rejected, (state, action) => {
        state.otpVerified = false;
        state.otpMessage = "";
        state.error = action.payload?.error || "OTP validation failed";
      })

      .addCase(loginUser.fulfilled, (state, action) => {
        state.error = null;
        state.userCreds = {};
        const refToken = Cookies.get("refreshToken");
        // if (refToken) {
        //   state.continueSession = true;
        //   return;
        // }
        state.tokenRefreshed = true;
        const expirationMinutes = 60;
        const expirationDate = new Date();
        expirationDate.setTime(
          expirationDate.getTime() + expirationMinutes * 60 * 1000
        );

        if (action.payload.data.alreadyLoggedIn) {
          state.alreadyLoggedData = {
            browser: action.payload.data.browser.split(" ")[0],
            device: action.payload.data.device,
            alreadyLoggedIn: action.payload.data.alreadyLoggedIn,
          };
          Cookies.set("refreshToken", action.payload.data.refreshToken);
          return;
        }
        state.alreadyLoggedData = {};
        const token = action.payload.data.Authorization;
        if (token) state.userLoggedIn = true;
        Cookies.set("token", token, { expires: expirationDate });
        Cookies.set("refreshToken", action.payload.data.refreshToken);
        Cookies.set("tokenExpires", expirationDate);
        getUserInfo()
          .then(async (data) => {
            try {
              const res = await api.post(`/users/saveLoginLogs`, data);
              console.log(res.data);
            } catch (error) {
              console.log("error saving login logs");
            }
          })
          .catch((error) =>
            console.error("Error retrieving user info:", error)
          );
      })
      .addCase(loginUser.rejected, (state, action) => {
        // alert(action.payload.error || "Hello");
        // if (action?.payload?.error) {
        //   alert(action.payload.error);
        // } else {
        //   alert("hello");
        // }

        state.userLoggedIn = false;
        if (
          action.payload.code == 422 &&
          action.payload.error.includes("Refresh Token")
        )
          return;
        state.error = action.payload.error || "Login Failed";
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload.data || {};
        state.documentsSubmitted = false;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.userLoggedIn = false;
        Cookies.remove("token");
        // Cookies.remove("refreshToken");
        localStorage.clear();
        sessionStorage.clear();
        state.error = action.payload?.error || "Invalid User Request";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.error = null;
        state.userData = action.payload.data || {};
        state.userUpdated = true;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      })
      .addCase(getFavGames.fulfilled, (state, action) => {
        state.favGames = action.payload.data || [];
      })
      .addCase(getFavGames.rejected, (state, action) => {
        state.error = action.payload?.error || "Invalid User Request";
      })
      .addCase(getLastPlayedGames.fulfilled, (state, action) => {
        state.lastPlayedGames = action.payload.data || [];
      })
      .addCase(getLastPlayedGames.rejected, (state, action) => {
        state.error = action.payload?.error || "Invalid Request";
      })
      .addCase(makeFavourite.fulfilled, (state, action) => {
        state.madeFavourite = true;
      })
      .addCase(makeFavourite.rejected, (state, action) => {
        state.error = action.error?.message || "Invalid Request";
      })
      .addCase(removeFavourite.fulfilled, (state, action) => {
        state.madeUnFavourite = true;
      })
      .addCase(removeFavourite.rejected, (state, action) => {
        state.error = action.error?.message || "Invalid Request";
      })
      .addCase(getLoginLogs.fulfilled, (state, action) => {
        state.loginLogs = action.payload.data;
      })
      .addCase(getLoginLogs.rejected, (state, action) => {
        state.error = action.payload?.error || "Invalid Request";
      })
      .addCase(uploadId.fulfilled, (state, action) => {
        state.documentsSubmitted = action.payload.status == 200;
      })
      .addCase(uploadId.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loggedOut = action.payload.status == 200;
        state.userLoggedIn = false;
        // Cookies.remove("refreshToken");
        Cookies.remove("tokenExpires");
        Cookies.remove("token");
        window.location.reload();
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      })
      .addCase(refreshJwtToken.pending, (state) => {
        state.tokenRefreshed = false;
      })
      .addCase(refreshJwtToken.fulfilled, (state, action) => {
        state.error = null;
        state.userLoggedIn = true;
        state.tokenRefreshed = true;
        const expirationMinutes = 60;
        const expirationDate = new Date();
        expirationDate.setTime(
          expirationDate.getTime() + expirationMinutes * 60 * 1000
        );
        const token = action.payload.data.Authorization;
        if (token) {
          Cookies.set("token", token, { expires: expirationDate });
          Cookies.set("refreshToken", action.payload.data.refreshToken);
          Cookies.set("tokenExpires", expirationDate, {
            expires: expirationDate,
          });
        }
      })
      .addCase(refreshJwtToken.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      })
      .addCase(getTransactions.pending, (state) => {
        state.transactions = null;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.data;
      })
      .addCase(getTransactions.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      })
      .addCase(getAstroData.pending, (state) => {
        state.astroData = {};
      })
      .addCase(getAstroData.fulfilled, (state, action) => {
        state.astroData = action.payload.data;
      })
      .addCase(getAstroData.rejected, (state, action) => {
        state.error = action.payload?.error || "Request Failed";
      });
  },
});

// Export the async thunk action creator and the reducer
export const {
  relogin,
  checkUserLogin,
  hideToast,
  storePhoneAndCountry,
  resetMessage,
  updateBalance,
  hidePopup,
  showKeepWorkingModal,
  hideKeepWorkingModal,
  tokenExpired,
  resetError,
  resetTransactions,
  saveUserCreds,
} = userSlice.actions;
export const { reducer } = userSlice;
export default userSlice.reducer;
