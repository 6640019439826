import React, { useMemo, useEffect } from "react";
import ChatBotOption from "./ChatBotOption";
import ReCAPTCHA from "react-google-recaptcha";
import MobileOtp from "./MobileOtp";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import {
  closeSignupModal,
  openSignupModal,
  openMobileOtpModal,
  closeMobileOtpModal,
  openThanksPopup,
  closeThanksPopup,
  openUsernameSetupModal,
} from "../../features/slices/ModalSlice";
import { updateSelectedItem } from "../../features/slices/CommonSlice";
import {
  createUser,
  resetError,
  storePhoneAndCountry,
} from "../../features/slices/UserSlice";

export default function () {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [phone, setPhone] = useState("");
  const [referredCode, setReferredCode] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isInfoIncorrect, setIsInfoIncorrect] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("");

  const [verified, setVerified] = useState(false);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toc, setTOC] = useState(false);
  const [receiveOffers, setReceiveOffers] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("IN");
  const [localError, setLocalError] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    // Extract referral code from the URL
    const path = window.location.pathname;
    const referralCode = path.split("/refer/")[1];
    if (referralCode) {
      setReferredCode(referralCode);
      if (isMobile) {
        dispatch(updateSelectedItem("signup"));
      } else {
        dispatch(openSignupModal());
      }
    }
  }, [dispatch, isMobile]);
  const offersCheck = (event) => {
    setReceiveOffers(event.target.checked);
  };
  const tocCheck = (event) => {
    setTOC(event.target.checked);
  };
  const handlePasswordInput = (event) => {
    setLocalError("");
    setPassword(event.target.value);
  };
  const matchPasswords = (event) => {
    setLocalError("");
    setConfirmPassword(event.target.value);
  };
  const handlePhoneNumberChange = (value) => {
    if (value) setIsPhoneValid(isValidPhoneNumber(value));
    setLocalError("");
    if (value && isValidPhoneNumber(value)) {
      const phoneNumber = parsePhoneNumber(value);
      setDefaultCountry(phoneNumber.country);
      setCountryCode("+" + phoneNumber.countryCallingCode);
      setPhone(phoneNumber.nationalNumber);
    } else {
      setPhone(value);
    }
  };
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  const signUpUser = (event) => {
    const form = document.querySelector(".needs-validation");
    if (form.checkValidity()) {
      event.preventDefault();
      if (!isValidPhoneNumber(countryCode + "" + phone)) {
        setLocalError("Phone number is not valid");
        return;
      }
      if (password.length < 8) {
        setLocalError("Password must have at least 8 characters");
        return;
      }
      if (password !== confirmPassword) {
        setLocalError("Both passwords should match");
        return;
      }
      if (!message.length && isPhoneValid) {
        const payload = {
          phone: phone,
          countryCode: countryCode,
          toc: toc,
          password: password,
          confirmPassword: password,
          receiveOffers: receiveOffers,
          referredCode: referredCode,
        };

        dispatch(
          storePhoneAndCountry({ countryCode: countryCode, phone: phone })
        );
        dispatch(resetError());
        dispatch(createUser(payload))
          .unwrap()
          .then(() => {
            dispatch(openMobileOtpModal()); // Show OTP modal upon successful signup
          })
          .catch((error) => {
            console.error("Signup error:", error);
            setLocalError("Signup failed. Please try again.");
          });
      }
    }
  };

  const styles = {
    backgroundColor: isMobile ? "#111111" : "none",
    height: "auto",
    paddingBottom: isMobile ? "100px" : "auto",
  };
  useMemo(() => {
    console.log("userdata", user);
    if (user.otpMessage) {
      if (isMobile) dispatch(updateSelectedItem("mobileOTP"));
      else dispatch(openMobileOtpModal(false));
      dispatch(closeSignupModal());
    }
  }, [user.otpMessage, dispatch]);

  useEffect(() => {
    if (user.otpVerified) {
      dispatch(closeMobileOtpModal());
      dispatch(openThanksPopup());

      // Set a timeout to open the Username Setup modal after Thanks popup
      setTimeout(() => {
        dispatch(closeThanksPopup());
        dispatch(openUsernameSetupModal());
      }, 3000); // Adjust timeout as needed
    }
  }, [user.otpVerified, dispatch]);

  useEffect(() => {
    if (user.error || localError) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [user.error, localError]);

  return (
    <div
      className={`${
        isMobile ? "form-mobile signup-form-inputs px-3 pt-3" : ""
      }`}
      style={styles}
    >
      {isMobile && (
        <p
          className="text-center"
          style={{ color: "#EFC04B" }}
          id="signupValidation"
        >
          Signup
        </p>
      )}

      <div className="incorrect-details-div mb-3">
        {user.error && <p className="text-center message">{user.error}</p>}
        {localError && <p className="text-center message">{localError} </p>}
        <ChatBotOption />
      </div>

      <form className="needs-validation signup-form">
        <b>Mobile</b>
        <div>
          <PhoneInput
            placeholder="Enter phone number"
            className={`mb-3 text-input ${
              isPhoneValid ? "is-valid form-control" : ""
            }`}
            onChange={handlePhoneNumberChange}
            // international
            defaultCountry="IN"
            autoComplete="off"
            required
          />
        </div>
        <b>Password</b>
        <div className="password overflow-hidden text-input">
          <input
            type={`${showPassword ? "text" : "password"}`}
            name="password"
            id="password"
            required
            className="form-control"
            onChange={handlePasswordInput}
            placeholder="Enter Password"
            autoComplete="off"
          />
          <i
            className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
            onClick={togglePassword}
            style={{ fontSize: "18px", marginTop: "-3px" }}
          ></i>
        </div>
        {password.length >= 8 ? (
          <p></p>
        ) : (
          <p className="normal--message">Password much have 8 Chars</p>
        )}
        <b>Confirm Password</b>
        <div className="password overflow-hidden text-input">
          <input
            type={`${showPassword ? "text" : "password"}`}
            name="password"
            id="confirmPassword"
            className="text-input form-control"
            required
            onChange={matchPasswords}
            placeholder="Confirm Password"
          />
          <i
            className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`}
            onClick={togglePassword}
            style={{ fontSize: "18px", marginTop: "-3px" }}
          ></i>
        </div>
        {confirmPassword.length && password === confirmPassword ? (
          <p></p>
        ) : (
          <p className="normal--message">Both Passwords should match</p>
        )}
        <b>Referral Code</b>
        <div>
          <div className="referral-code overflow-hidden d-flex justify-content-between text-input">
            <input
              type="text"
              name="referredCode"
              value={referredCode}
              readOnly // Makes the field non-editable
              className="text-input form-control"
              id="rf_code"
            />
          </div>
        </div>

        {/* <div className="mt-4 recaptcha-div">
        <ReCAPTCHA
          sitekey="6LcS-PglAAAAAGQ_mQuYeOoi_CHa4Gzd7iFBsYGt"
          onChange={onChange}
          size="normal"
          theme="dark"
          className="custom-recaptcha"
        />
      </div> */}

        <div className="checkboxes pt-4">
          <span className="d-flex">
            <input
              className="form-check-input mt-0"
              type="checkbox"
              onChange={tocCheck}
              required
              aria-label="Checkbox for following text input"
            />
            <p className="remember-me">
              I confirm I’m 18+ and agree with the terms and conditions. For
              more information on how we process your personal data please see
              our{" "}
              <a href="/privacy-policy" style={{ textDecoration: "none" }}>
                privacy policy.
              </a>
            </p>
          </span>

          <span className="d-flex">
            <input
              className="form-check-input mt-0"
              type="checkbox"
              onChange={offersCheck}
              aria-label="Checkbox for following text input"
              required
            />
            <p className="remember-me">
              I agree to receive free spins, exclusive offers and awesome
              updates via my registered contact details. Opt out is available at
              any time on your profile page.
            </p>
          </span>
        </div>

        <button
          className="login-btn mb-3 mt-3 p-2"
          type="submit"
          onClick={signUpUser}
        >
          Signup
        </button>
      </form>
    </div>
  );
}
